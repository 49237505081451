
// import {conf_env} from '../../conf_env'
import { getUrlParam } from "./util";

const resortId = getUrlParam('resortId');
const roomId = getUrlParam('roomId') || null;
const token = getUrlParam('token') || null;


localStorage.setItem("resortId",resortId);

var  paramUrl = "?resortId="+resortId;
if (roomId != null) {
    paramUrl += "&roomId="+roomId;
    localStorage.setItem("roomId",roomId);
}
if (token != null) {
    paramUrl += "&token="+token;
    localStorage.setItem("token",token);
}
else {
    localStorage.removeItem("token");
}

const settings_env = {
    API_BASE_URL: window.env.API_BASE_URL,
    API_BASE_URL_POST: window.env.API_BASE_URL_POST,
    API_ENDPOINT_POST: window.env.API_ENDPOINT_POST,
    API_KEY:window.env.API_KEY,
    API_VERSION:window.env.API_VERSION
};

const version = settings_env.API_VERSION;
const apibase_url = settings_env.API_BASE_URL;
const apibase_url_post = settings_env.API_BASE_URL_POST;
const api_endpoint_post = settings_env.API_ENDPOINT_POST || 'roomservice';

const API_URL = {
    "base" : apibase_url,
    "sendinfo" : apibase_url_post + api_endpoint_post,
    "resort" : apibase_url + resortId + "?v="+ version,
    "info" : apibase_url + resortId + "/contents?contentType=rubric_room_service&v="+ version,
    "menu_product" : apibase_url + resortId + "/contents?contentType=menu_product&v="+ version,
}

const HEADER_API_URL = {
    "accept_language" : localStorage.getItem('currentLang'),
    "api_key" : settings_env.API_KEY,
}

export {API_URL, resortId, HEADER_API_URL, paramUrl,roomId};

