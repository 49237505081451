import React from 'react';
import '../styles/App.css';
import { withNamespaces } from 'react-i18next';
import { Router } from "@reach/router";

import Home from './screen/Home';
import Choice from './screen/Choice';
import Contact from './screen/Contact';
import Commande from './screen/Commande';

function App({t}) {
  var classGeLang = 'en-lang';
  require('dotenv').config();
  if(localStorage.getItem('currentLang') === 'fr'){
    classGeLang = 'fr-lang';
  }
  else if(localStorage.getItem('currentLang') === 'pt'){
    classGeLang = 'pt-lang';
  }
  return (
    <Router className={"App " + classGeLang}>
      <Home varT={t} path="/"/>
      <Choice varT={t} path="/choice"/>
      <Contact varT={t} path="/contact"/>
      <Commande varT={t} path="/commande"/>
    </Router>
  );
}

export default withNamespaces()(App);
  