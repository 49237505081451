import React , { Component } from 'react';
import { navigate } from "@reach/router";
import {API_URL, HEADER_API_URL, paramUrl} from '../../utils/config';
import moment from 'moment';
import HeaderChoice from '../component/HeaderChoice';
import Loader from '../component/Loader/Loader';


class Commande extends Component {

  constructor(props){
    super(props);
  }

  state = {
    base_url: localStorage.getItem("base_url"),
    ResortId: localStorage.getItem("resortId"),
    RoomId : localStorage.getItem("roomId"),
    Token : localStorage.getItem("token"),
    Phone : localStorage.getItem("phone"),
    Email : localStorage.getItem("email"),
    NbPerson : localStorage.getItem("nbperson"),
    MenuId : localStorage.getItem("idMenuChoiceClient"),
    DeliveryTimeFull : localStorage.getItem("date_delivery"),
    product_list : JSON.parse(localStorage.getItem("product_list")),
    product_list_info : JSON.parse(localStorage.getItem("product_list_info")),
    menuChoice : JSON.parse(localStorage.getItem("menuChoice")),
    showmodal : false,
    showloader : false,
    showmodalerror: false,
    showmodalerrorroom: false,
    showmodalallerror: false,
    showcontentmodalerror: true,
  }


    ConfirmOrder = () => {
        this.setState({ showloader: !this.state.showloader });
        fetch(API_URL.sendinfo, {
            method: 'POST',
            headers: {
                "Accept-Language": HEADER_API_URL.accept_language,
                "Content-Type": 'application/json',
                "x-api-key": HEADER_API_URL.api_key,
                "Authorization": "Bearer " + this.state.Token
            },
            body: JSON.stringify({
                "resort": this.state.ResortId,
                "room": this.state.RoomId,
                "phone": this.state.Phone,
                "email": this.state.Email,
                "date_delivery": this.state.DeliveryTimeFull,
                "menu_id": this.state.MenuId,
                "nb_person_qty": this.state.NbPerson,
                "product_list": this.state.product_list
            })
        }).then(function(response) {
            return response.json();
        }).then((data) => {
            console.log(data)
            if(data.code){
                if(data.code === 2068){
                    this.setState({ showloader: !this.state.showloader })
                    this.setState({ showmodal: !this.state.showmodal });
                    this.setState({ showmodalerror: !this.state.showmodalerror })
                    this.setState({ showcontentmodalerror: !this.state.showcontentmodalerror })
                }else if(data.code === 2067){
                    this.setState({ showloader: !this.state.showloader })
                    this.setState({ showmodal: !this.state.showmodal });
                    this.setState({ showcontentmodalerror: !this.state.showcontentmodalerror })
                    this.setState({ showmodalerrorroom: !this.state.showmodalerrorroom })
                }else{
                    this.setState({ showloader: !this.state.showloader })
                    this.setState({ showmodal: !this.state.showmodal });
                    this.setState({ showcontentmodalerror: !this.state.showcontentmodalerror })
                    this.setState({ showmodalallerror: !this.state.showmodalallerror })
                }
            }else{
                this.setState({ showloader: !this.state.showloader })
                this.setState({ showmodal: !this.state.showmodal })
                this.setState({ showmodalerror: false })
                this.setState({ showcontentmodalerror: true })
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }


    FinalCommande = () => {
        this.setState({ showmodal: !this.state.showmodal });
        localStorage.removeItem('menuChoice');
        localStorage.removeItem('date_delivery');
        localStorage.removeItem('idMenuChoiceClient');
        localStorage.removeItem('nbperson');
        localStorage.removeItem('product_list');
        localStorage.removeItem('product_list_info');
        localStorage.removeItem('email');
        localStorage.removeItem('phone');
        localStorage.removeItem('roomId');
        localStorage.removeItem('date_delivery_to');
        localStorage.removeItem('date_delivery_from');
        navigate(`/`+paramUrl);
        window.location.reload();
    }

  render() {
    const varT = this.props.varT;   
    const DeliveryTime = moment.utc(this.state.DeliveryTimeFull).format("H : mm");
    const classShowModal = this.state.showmodal ? "show" : "hide";
    const classShowLoader = this.state.showloader ? "show" : "hide";
    const classShowModalError = this.state.showmodalerror ? "show" : "hide";
    const classShowModalErrorRoom = this.state.showmodalerrorroom ? "show" : "hide";
    const classShowModalAllError = this.state.showmodalallerror ? "show" : "hide";
    const classShowContentModalError = this.state.showcontentmodalerror ? "show" : "hide";
    
    const linkBkgMenuItem = this.state.menuChoice.field_image.file.url.replace("/sites/default/files/","");
    const MenuItemBkg = {backgroundImage: 'url('+ this.state.base_url + '/sites/default/files/styles/card_big/public/' + linkBkgMenuItem +')'}
    return (
      <div className="page-commande">
        <HeaderChoice BaseUrl={this.state.base_url}/>
        <div className="commande-block-page">
            <div className="title-page">{varT('room_services_form_summary_title')}</div>
            <div className="com-block delivery-commande-block">
                <div className="title-delivery-block">
                    <div className="title-delivery-com">{varT('room_services_form_summary_info_delivery')}</div>
                    <div className="modify-delivery-com" onClick={() => navigate(`/contact`+paramUrl)}>{varT('room_services_form_summary_modify')}</div>
                </div> 
                <div className="block-info-com">
                    <div className="info-com">
                        <div className="label-com">{varT('room_services_form_summary_room')}</div>
                        <div className="value-com">#{this.state.RoomId}</div>
                    </div> 
                    <div className="info-com">
                        <div className="label-com">{varT('room_services_form_summary_phone')}</div>
                        <div className="value-com">{this.state.Phone}</div>
                    </div> 
                    <div className="info-com">
                        <div className="label-com">{varT('room_services_form_summary_email')}</div>
                        <div className="value-com">{this.state.Email}</div>
                    </div> 
                </div>
            </div>
            <div className="com-block order-commande-block">
                <div className="title-delivery-block">
                    <div className="title-delivery-com">{varT('room_services_form_summary_info_order')}</div>
                    <div className="modify-delivery-com" onClick={() => navigate(`/choice`+paramUrl)}>{varT('room_services_form_summary_modify')}</div>
                </div> 
                <div className="block-info-com">
                    <div className="info-com">
                        <div className="label-com">{varT('room_services_form_summary_time')}</div>
                        <div className="value-com">{DeliveryTime}</div>
                    </div> 
                    <div className="info-com">
                        <div className="label-com">{varT('room_services_form_summary_nb_person')}</div>
                        <div className="value-com">{this.state.NbPerson}</div>
                    </div> 
                    {Object.keys(this.state.product_list_info).map((productitem, x) => {
                        return (
                            <div className="info-com" key={x}>
                                <div className="label-com">{productitem}</div>
                                <div className="value-com">{this.state.product_list_info[productitem]}</div>
                            </div> 
                        )
                    })}
                </div>
            </div>
            <div className="submit-block">
                <div className="btn yellow btn-submit" onClick={() => this.ConfirmOrder()}><span>{varT('room_services_cta_confirm_order')}</span></div>
            </div>
        </div>
        <div className={"modal-confirm-commande " + classShowModal}>
            <div className={"content-modal valid-command " + classShowContentModalError}>
                <div className="close-modal" onClick={() => this.FinalCommande()}></div>
                <div className="picture-modal" style={MenuItemBkg}></div>
                <div className="title-modal">{this.state.menuChoice.title}</div>
                <div className="text-modal">{varT('room_services_popin_confirm_desc', { hour_start: localStorage.getItem("date_delivery_from"), hour_end: localStorage.getItem("date_delivery_to")})}</div>
            </div>
            <div className={"content-modal error-modal error-same-menu-room " + classShowModalError}>
                <div className="close-modal" onClick={() => this.FinalCommande()}></div>
                <div className="title-modal">{varT('room_services_popin_conflict')}</div>
                <div className="text-modal">{varT('room_services_popin_conflict_text')}</div>
            </div>
            <div className={"content-modal error-modal error-room " + classShowModalErrorRoom}>
                <div className="close-modal" onClick={() => this.FinalCommande()}></div>
                <div className="title-modal">{varT('room_services_popin_room_not_allowed')}</div>
                <div className="text-modal">{varT('room_services_popin_room_not_allowed_text')}</div>
            </div>
            <div className={"content-modal error-modal " + classShowModalAllError}>
                <div className="close-modal" onClick={() => this.FinalCommande()}></div>
                <div className="title-modal">{varT('room_services_popin_room_error')}</div>
                <div className="text-modal">{varT('room_services_popin_room_error_text')}</div>
            </div>
        </div>
        <Loader classLoadName={classShowLoader}/>
      </div>
    );
  }
}

export default Commande;
  