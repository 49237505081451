import React , { Component } from 'react';
import {API_URL, HEADER_API_URL} from '../../utils/config';
import moment from 'moment';

class DeliveryTime extends Component {

  constructor(props){
    super(props);
  }

  state = {
    infos: null,
    base_url: null,
  }

  componentDidMount() {
    fetch(API_URL.resort,{
      method: 'GET',
      headers: {
        "Accept-Language": HEADER_API_URL.accept_language,
        "x-api-key": HEADER_API_URL.api_key
      },
    })
    .then((res) => {
      if (!this.state.base_url) {
        this.setState({ base_url: res.headers.get("content-base-url") })
      }
      return res.json();
    }).then((data) => {
      if (!this.state.infos) {
        this.setState({ infos: data }, () => {
          //   console.log('resultdeliverytime',this.state.infos);
          var timechoice = this.state.infos.field_roomservice_timeslots[0].field_time_range.from;
          var timechoicefrom= this.state.infos.field_roomservice_timeslots[0].field_time_range.from;
          var timechoiceto = this.state.infos.field_roomservice_timeslots[0].field_time_range.to;
          this.DefaultTimeChoice(timechoice,timechoicefrom,timechoiceto,);
          })
      }
    })
    .catch(console.log)

   
  }

  DefaultTimeChoice = (timechoice,timechoicefrom,timechoiceto) => {
    //  Fix: using utc here create a problem, for example a resort in UTC-4,
    // an order at 22h of the 20th will be considerd the 21th in UTC so with +1 day the 22nd
    // The server will consider the resort time at the right date the 20th and expect a delivery date the 21 not the 22nd and reject the order.
    // A device not in the local time will still have issues but that would also be the case with the previous code
    //const timechoisi = moment.utc().startOf('day').add(timechoice, 'seconds').add(1, 'days').format();
    const timechoisi = moment().startOf('day').add(timechoice, 'seconds').add(1, 'days').format("YYYY-MM-DDThh:mm:ss") + 'Z'; // I don't think the 'Z' is necessary just wanted to be as close as possible from the previous code
    const timechoisifrom = moment.utc(timechoicefrom * 1000).format("H:mm");    
    const timechoisito = moment.utc(timechoiceto * 1000).format("H:mm");

    this.props.TimeSlot(timechoisi,timechoisifrom,timechoisito);
  }

  ChangeTime = (e) => {
    // console.log("e",JSON.parse(e.target.value).from)
    //const timechoisi = moment.utc().startOf('day').add(JSON.parse(e.target.value).from, 'seconds').add(1, 'days').format();
    const timechoisi = moment().startOf('day').add(JSON.parse(e.target.value).from, 'seconds').add(1, 'days').format("YYYY-MM-DDThh:mm:ss") + 'Z'; // I don't think the 'Z' is necessary just wanted to be as close as possible from the previous code
    const timechoisifrom = moment.utc(JSON.parse(e.target.value).from * 1000).format("H:mm");
    const timechoisito = moment.utc((JSON.parse(e.target.value).to)* 1000).format("H:mm");
    // console.log(timechoisi,timechoisifrom,timechoisito)
    this.props.TimeSlot(timechoisi,timechoisifrom,timechoisito);
  }

  render() {
    const varT = this.props.varT; 
    if(this.state.infos == null){
        return '';
    }
    const timeslots = this.state.infos.field_roomservice_timeslots;
    return (
      <div className="delivery-time-block">
        <select className="delivery-time-select" onChange={this.ChangeTime}>
            {timeslots.map((timeslot, x) => {
                return (
                  <option key={x} value={JSON.stringify(timeslot.field_time_range)}>
                      {moment.utc(timeslot.field_time_range.from * 1000).format(varT("format_delivery_menu_product"))} - {moment.utc(timeslot.field_time_range.to * 1000).format(varT("format_delivery_menu_product"))}
                  </option>
                ) 
            })}
        </select>
      </div>
    );
  }
}

export default DeliveryTime;
  